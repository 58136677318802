import axios from 'axios'


function forceFileDownload(response, title) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', title)
    document.body.appendChild(link)
    link.click()
}

export default {
    async download(url) {
        let fileName = url.split("/").reverse()[0]
        axios({
            method: 'get',
            url: url,
            responseType: 'arraybuffer',
        })
        .then((response) => forceFileDownload(response, fileName))
        .catch(() => console.log('Error occured'))
    }
}