<template>
    <div>
        <top-menu @toggleDrawer="showExpand" :is-dashboard="true" :style="{'position':  nativeWidth ? 'static': 'fixed'}" />
        <user-drawer-menu-top v-if="nativeWidth" />
        <user-drawer-menu :mini="mini" v-else />
        <v-main>
            <v-container v-show="loading" class="fill-height">
                <v-col class="text-center">
                    <v-progress-circular :size="50" color="primary" indeterminate/>
                </v-col>
            </v-container>

            <v-container v-show="!loading">
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import UserDrawerMenu from "../components/dashboard/UserDrawerMenu";
    import UserDrawerMenuTop from "../components/dashboard/UserDrawerMenuTop";
    import TopMenu from "../components/TopMenu";
    export default {
        name: "user-dashboard-layout",
        components: {TopMenu, UserDrawerMenu, UserDrawerMenuTop},
        props: {
            loading: {
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {
                mini: this.$vuetify.breakpoint.mdAndDown,
                nativeWidth: window.innerWidth < 600,
            }
        },
        methods: {
            showExpand() {
                if (this.nativeWidth) {
                    let element = document.getElementById("topNavDrawer")
                    element.className === "top-nav" ? element.className += " responsive" : element.className = "top-nav"
                }
                this.mini = !this.mini
            }
        },
        created() {
            window.addEventListener('resize' , () => {
                this.nativeWidth = window.innerWidth < 600
            })
        }
    }
</script>

<style scoped>

</style>
