export default [
    {
        to: {name: 'dashboard.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.billing'},
        icon: 'mdi-credit-card',
        text: 'Billing',
    },
    {
        to: {name: 'dashboard.billing.select-plan'},
        icon: 'mdi-credit-card',
        text: 'SelectPlan',
    },
    {
        to: {name: 'dashboard.videos'},
        icon: 'mdi-message-video',
        text: 'Videos',
    },
    {
        to: {name: 'dashboard.guides'},
        icon: 'mdi-file-document-multiple',
        text: 'Guides',
    },
    {
        to: {name: 'dashboard.infographics'},
        icon: 'mdi-file-chart-outline',
        text: 'Infographics',
    },
    {
        to: {name: 'dashboard.social-graphics'},
        icon: 'mdi-instagram',
        text: 'SocialGraphic',
    },
    {
        to: {name: 'dashboard.market-reports'},
        icon: 'mdi-file-document-multiple',
        text: 'Marketreports',
    },
    {
        to: {name: 'dashboard.tags'},
        icon: 'mdi-file-document-multiple',
        text: 'BrowseByTopic',
    },
]
