<template>
    <user-dashboard-layout>
        <v-container>
            <v-row dense>
                <v-col md="4" sm="12">
                    <v-carousel  height="auto" v-model="carousel">
                        <v-carousel-item
                            v-for="(image, i) in guide.gallery_images"
                            :key="image+i"
                        >
                            <v-img contain :src="image" />
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col md="8" sm="12" class="pl-15 mt-15">
                    <h1 v-text="guide.name"/>
                    <p v-text="guide.description"/>
                    <v-row>
                        <v-col>
                            <v-list shaped rounded>
                                <v-list-item-group color="primary">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="py-1">
                                                <v-btn @click="incrementDownloads" class="mx-3" x-small target="_blank">
                                                    {{$t('guides.' + guides.btndld)}}
                                                </v-btn>
                                                <v-btn class="mx-3" x-small @click="handleSendEmail">{{$t('guides.' + guides.btnml)}}</v-btn>
                                                <v-btn
                                                    v-if="this.guide.powerpoint"
                                                    class="mx-3"
                                                    x-small
                                                    :href="this.guide.powerpoint"
                                                    target="_blank">{{$t('guides.' + guides.btned)}}</v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                   </v-row>

                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import AxiosHelper from "../../../helpers/AxiosHelper";
    import Guide from "../../../models/Guide";
    import guides from "@/configs/guides";

    export default {
        name: "Show",
        components: {UserDashboardLayout},
        data: function () {
            return {
                carousel: 0,
                guide: {
                    gallery_images: [],
                    files: '',
                },
                guides
            }
        },
        methods: {
            handleSendEmail() {},
            incrementDownloads() {
                window.axios.post('/api/guides/' + this.guide.id + '/increment-downloads')
                AxiosHelper.download(this.guide.files)
            },
            getPptxByLang(lang) {
                return this.guide.powerpoint.find(item => item.language === lang);
            }
        },
        async mounted() {
            const guideId = this.$route.params.id
            this.guide = await Guide.find(guideId)
        }
    }
</script>

<style scoped>

</style>
