<template>
    <div class="top-nav" id="topNavDrawer" style="width: 100%">
        <v-list dense color="#31303d" dark>
            <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-img :src="$auth.user().avatar_url"></v-img>
                </v-list-item-avatar>

                <v-list-item-title>
                    {{$auth.user().name}}
                    <v-btn x-small icon :to="{name: 'dashboard.profile'}">
                        <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item-title>
            </v-list-item>

            <v-list-item link :to="link.to" v-for="link in menu" :key="JSON.stringify(link)">
                <v-list-item-icon>
                    <v-icon>{{link.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('menu.' + link.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    import menu from "@/configs/menu";

    export default {
        name: "UserDrawerMenu",
        props: ['mini'],
        data () {
            return {
                drawer: false,
                menu: menu,
            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">

.v-list-item:not(:first-child) {display: none;}

.top-nav.responsive .v-list .v-list-item {
    display: flex;
    text-align: left;
}

</style>
